import transport from "@recare/core/model/api/transport";
import {
  PendingSealdAccess,
  RCryptoKey,
  SealdAccessPayload,
  SessionKey,
} from "@recare/core/types";

export default {
  updateEncryptedPrivateKey(
    accountId: number,
    cryptoKeys: { private_key_fivek: string },
    token: string,
  ): Promise<any> {
    return transport.put({
      route: `/crypto/account/${accountId}`,
      body: {
        account_id: accountId,
        ...cryptoKeys,
      },
      queryParams: null,
      options: { token, routeName: "updateKeys" },
    });
  },
  postKeys(
    accountId: number,
    cryptoKeys: RCryptoKey,
    token: string,
  ): Promise<any> {
    return transport.post({
      route: `/crypto/account/${accountId}`,
      body: {
        ...cryptoKeys,
        account_id: accountId,
      },
      queryParams: null,
      options: { token, routeName: "postKeys" },
    });
  },
  getPendingKeys(): Promise<any> {
    return transport.get({
      route: `/crypto/pending`,
      options: {
        routeName: "getPendingKeys",
      },
    });
  },
  updatePendingSessionKey(key: SessionKey): Promise<any> {
    return transport.put({
      route: `/crypto/pending`,
      body: key,
      queryParams: {
        routeName: "updatePendingKeys",
      },
    });
  },
  getPendingSealdAccess(): Promise<PendingSealdAccess[] | null> {
    return transport.get({
      route: `/seald/access/pending`,
      options: {
        routeName: "getPendingSealdAccess",
      },
    });
  },
  createSealdAccess(sealdAccess: Partial<SealdAccessPayload>): Promise<null> {
    return transport.post({
      route: `/seald/access/create?sharePatient`,
      body: sealdAccess,
      options: {
        routeName: "createSealdAccess",
      },
    });
  },
  checkSealdGroupAccess(payload: {
    careprovider_id?: number;
    careseeker_id?: number;
  }): Promise<{ exists: boolean }> {
    return transport.post({
      route: `/seald/group/check`,
      body: payload,
      options: {
        routeName: "checkSealdGroupAccess",
      },
    });
  },
};
